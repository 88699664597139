

























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import DatePicker from '@/components/form/DatePicker.vue';
import { InternalAccountType, OpenInternalAccount } from '@/modules/listOfAssets/types';
import { ApiResponse } from '@/components/types';
import { createOpenInternalAccount } from '@/modules/listOfAssets/model';
import { OPEN_INTERNAL_ACCOUNT } from '@/modules/listOfAssets/store/internalAccount';
import { INTERNAL_ACCOUNT } from "@/modules/listOfAssets/statics";

const InternalAccount = namespace('listOfAssets/internalAccount');

@Component({
  components: {
    DatePicker
  }
})
export default class AccountSelectForm extends Vue {
  @Prop({ type: String, required: true }) listOfAssetsId!: string;

  @InternalAccount.Action(OPEN_INTERNAL_ACCOUNT) openAccount!: (params: OpenInternalAccount) => Promise<ApiResponse>;

  error: any = null;

  type: InternalAccountType = INTERNAL_ACCOUNT;

  value: OpenInternalAccount = createOpenInternalAccount(this.listOfAssetsId, this.type);

  reset() {
    setTimeout(() => {
      this.error = null;
      this.value = createOpenInternalAccount(this.listOfAssetsId, this.type);
    }, 300);
  }

  cancel() {
    this.reset();
    this.$emit('cancel');
  }

  back() {
    this.reset();
    this.$emit('back');
  }

  save() {
    this.error = null;

    return this.openAccount(this.value);
  }
}
