






































import { Component, Prop, Vue } from 'vue-property-decorator';
import { InternalAccount, ListsOfAssets } from '../types';
import CardRow from '@/components/CardRow.vue';
import TransactionExportDialog from './TransactionExportDialog.vue';
import FinanceAccountRemoveDialog from './FinanceAccountRemoveDialog.vue';
import VoucherExportDialog from '@/modules/listOfAssets/components/VoucherExportDialog.vue';

@Component({
  components: {
    VoucherExportDialog,
    FinanceAccountRemoveDialog,
    TransactionExportDialog,
    CardRow
  }
})
export default class InternalAccountCard extends Vue {
  @Prop({ type: Object, required: true }) account!: InternalAccount;
  @Prop({ type: Object, required: true }) listOfAssets!: ListsOfAssets;
  @Prop({ type: Boolean, default: false }) showExport!: boolean;
  @Prop({ type: String, default: 'finance' }) referer!: string;

  get title() {
    if (this.account.state === 'open') {
      return this.account.note || 'Bargeldkonto';
    }

    return `${this.account.note || 'Bargeldkonto'} (${this.$i18n.t(`financeAccount.state.closed`)})`;
  }
}
