













import { Component, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';

@Component({
  inheritAttrs: false
})
export default class IncomeProducingSelect extends Vue {
  @Prop({ type: Boolean }) value!: boolean;

  get list(): Array<{ value: boolean, text: TranslateResult }> {
    return [
      { value: true, text: this.$i18n.t(`financeAccount.incomeProducing.yes`) },
      { value: false, text: this.$i18n.t(`financeAccount.incomeProducing.no`) },
    ];
  }

  created() {
    this.$emit('input', this.value);
  }
}
