

























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import uuid from 'uuid/v4';
import DatePicker from '@/components/form/DatePicker.vue';

interface ManualCashReceipt {
  date: string | null;
  money: number;
  note: string;
  copy: boolean;
}

@Component({
  components: {
    DatePicker
  }
})
export default class ManualCashReceiptDialog extends Vue {
  @Prop({ type: String, required: true }) listOfAssetsId!: string;
  @Prop({ type: String, required: true }) name!: string;

  uuid: string = uuid();

  error: any = null;
  dialog: boolean = false;
  loading: boolean = false;

  value: ManualCashReceipt = {
    date: null,
    note: '',
    money: 0,
    copy: false
  };

  file: string = '';

  get download() {
    return `Quittung-${this.name}.pdf`;
  }

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value === false) {
      setTimeout(() => {
        this.error = null;
        this.file = '';
        this.loading = false;

        // @ts-ignore
        this.$refs.form.reset();
      }, 200);

      return;
    }

    this.value = {
      date: null,
      note: '',
      money: 0,
      copy: false
    };
  }

  async save() {
    this.error = null;

    // @ts-ignore
    const result = await this.$refs.form.validate();

    if (!result) return;

    this.loading = true;

    try {
      const response = await this.$http.get(`api/financial-administration/list-of-assets/${this.listOfAssetsId}/manually-receipt-pdf`, {
        responseType: 'blob',
        params: this.value
      });

      this.file = URL.createObjectURL(response.data);
    } catch (error) {
      this.error = error;
    }

    this.loading = false;
  }
}
