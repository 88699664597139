








import { Component, Prop, Vue } from 'vue-property-decorator';
import CashAccountForm from './CashAccountForm.vue';
import { OTHER_ACCOUNT } from '@/modules/listOfAssets/statics';

@Component({
  components: {
    CashAccountForm
  }
})
export default class OtherAccountForm extends Vue {
  @Prop({ type: String, required: true }) listOfAssetsId!: string;

  type = OTHER_ACCOUNT;
}
