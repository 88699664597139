




























import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class AccountSelectForm extends Vue {
  options = [
    { component: 'giro-account-form', label: 'Girokonto' },
    { component: 'savings-account-form', label: 'Sparkonto' },
    { component: 'build-loan-contract-form', label: 'Bausparvetrag' },
    { component: 'accounts-payment-form', label: 'Verbindlichkeiten / Forderungen' },
    { component: 'stocks-equity-funds-form', label: 'Wertpapiere, Aktienfonds' },
    { component: 'deposit-account-form', label: 'Kautionskonto' },
    { component: 'funerary-account-form', label: 'Bestattungskonto' },
    { component: 'account-in-credit-form', label: 'Sonstiges Guthabenkonto' },
    { component: 'other-account-form', label: 'Sonstiges Konto' },
    { component: 'internal-account-form', label: 'Internes Konto' }
  ];
}
