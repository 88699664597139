
































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_INTERNAL_ACCOUNTS, GET_INTERNAL_ACCOUNTS } from '../store/internalAccount';
import { InternalAccount, ListOfAssets } from '../types';
import { ApiResponse } from '@/components/types';
import CardRow from '@/components/CardRow.vue';
import InternalAccountCard from './InternalAccountCard.vue';

const InternalAccount = namespace('listOfAssets/internalAccount');

@Component({ components: { InternalAccountCard, CardRow } })
export default class InternalAccountsPlugin extends Vue {
  @Prop({ type: Object, required: true }) listOfAssets!: ListOfAssets;
  @Prop({ type: String, default: 'finance' }) referer!: string;
  @Prop({ type: Boolean, default: false }) showExport!: boolean;

  @InternalAccount.Getter(GET_INTERNAL_ACCOUNTS) findByListOfAssets!: (listOfAssetsId: string) => InternalAccount[];
  @InternalAccount.Action(FETCH_INTERNAL_ACCOUNTS) fetch!: (listOfAssetsId: string) => Promise<ApiResponse<InternalAccount[]>>;

  loading: boolean = false;
  showClosed: boolean = false;

  get accounts(): InternalAccount[] {
    return this.findByListOfAssets(this.listOfAssets.id).filter((account) => {
      if (this.showClosed) {
        return true;
      }

      return account.state === 'open';
    });
  }

  created() {
    return this.fetch(this.listOfAssets.id);
  }
}
