








import { Component, Prop, Vue } from 'vue-property-decorator';
import BankAccountForm from './BankAccountForm.vue';
import { GIRO_ACCOUNT } from '@/modules/listOfAssets/statics';

@Component({
  components: {
    BankAccountForm
  }
})
export default class GiroAccountForm extends Vue {
  @Prop({ type: String, required: true }) listOfAssetsId!: string;

  type = GIRO_ACCOUNT;
}
