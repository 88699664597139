


















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import AccountSelectForm from './FinanceDialog/AccountSelectForm.vue';
import GiroAccountForm from './FinanceDialog/GiroAccountForm.vue';
import SavingsAccountForm from './FinanceDialog/SavingsAccountForm.vue';
import BuildLoanContractForm from './FinanceDialog/BuildLoanContractForm.vue';
import AccountsPaymentForm from './FinanceDialog/AccountsPaymentForm.vue';
import StocksEquityFundsForm from './FinanceDialog/StocksEquityFundsForm.vue';
import OtherAccountForm from './FinanceDialog/OtherAccountForm.vue';
import InternalAccountForm from './FinanceDialog/InternalAccountForm.vue';
import AccountInCreditForm from './FinanceDialog/AccountInCreditForm.vue';
import FuneraryAccountForm from './FinanceDialog/FuneraryAccountForm.vue';
import DepositAccountForm from './FinanceDialog/DepositAccountForm.vue';

@Component({
  components: {
    AccountSelectForm,
    GiroAccountForm,
    SavingsAccountForm,
    BuildLoanContractForm,
    AccountsPaymentForm,
    StocksEquityFundsForm,
    OtherAccountForm,
    AccountInCreditForm,
    DepositAccountForm,
    FuneraryAccountForm,
    InternalAccountForm
  }
})
export default class FinanceDialog extends Vue {
  @Prop({ type: String, required: true }) listOfAssetsId!: string;

  dialog: boolean = false;
  financeComponent = 'account-select-form';

  @Watch('dialog')
  onChange(dialog: boolean) {
    if (!dialog) {
      this.financeComponent = 'account-select-form';
    }
  }
}
