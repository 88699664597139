








import { Component, Prop, Vue } from 'vue-property-decorator';
import CreditAccountForm from './CreditAccountForm.vue';
import { FUNERARY_ACCOUNT } from '../../statics';

@Component({
  components: {
    CreditAccountForm
  }
})
export default class DepositAccountForm extends Vue {
  @Prop({ type: String, required: true }) listOfAssetsId!: string;

  type = FUNERARY_ACCOUNT;
}
