

































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { REMOVE_ACCOUNT } from '../store/financeAccount';
import { Category, FinanceAccount } from '../types';
import { ApiResponse } from '@/components/types';

const FinanceAccount = namespace('listOfAssets/financeAccount');

@Component({})
export default class FinanceAccountRemoveDialog extends Vue {
  @Prop({ type: Object, required: true }) account!: FinanceAccount;
  @FinanceAccount.Action(REMOVE_ACCOUNT) removeAccount!: (params: { finance_account_id: string, category: Category }) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;

  @Watch('dialog')
  onDialogChange() {
    this.error = null;
  }

  remove() {
    this.error = null;

    return this.removeAccount({ finance_account_id: this.account.id, category: this.account.category });
  }
}
