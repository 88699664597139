


























import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { GET_BANK_ACCOUNTS } from '../../../store/bankAccount';
import { GET_CASH_ACCOUNTS } from '../../../store/cashAccount';
import { GET_CREDIT_ACCOUNTS } from '../../../store/creditAccount';
import { FinanceAccount, ListOfAssets } from '../../../types';
import CardRow from '@/components/CardRow.vue';
import FinanceDialog from '@/modules/listOfAssets/components/FinanceDialog.vue';

const BankAccount = namespace('listOfAssets/bankAccount');
const CreditAccount = namespace('listOfAssets/creditAccount');
const CashAccount = namespace('listOfAssets/cashAccount');

@Component({ components: { FinanceDialog, CardRow } })
export default class FinanceAccountList extends Vue {
  @Prop({ type: Object, required: true }) listOfAssets!: ListOfAssets;
  @Prop({ type: String, default: 'finance' }) referer!: string;

  @BankAccount.Getter(GET_BANK_ACCOUNTS) listBankAccounts!: (listOfAssetsId: string) => FinanceAccount[];
  @CashAccount.Getter(GET_CASH_ACCOUNTS) listCashAccounts!: (listOfAssetsId: string) => FinanceAccount[];
  @CreditAccount.Getter(GET_CREDIT_ACCOUNTS) listCreditAccounts!: (listOfAssetsId: string) => FinanceAccount[];

  loading: boolean = false;

  get bankAccounts(): FinanceAccount[] {
    return this.listBankAccounts(this.listOfAssets.id);
  }

  get cashAccounts(): FinanceAccount[] {
    return this.listCashAccounts(this.listOfAssets.id);
  }

  get creditAccounts(): FinanceAccount[] {
    return this.listCreditAccounts(this.listOfAssets.id);
  }

  get financeAccounts(): FinanceAccount[] {
    return [...this.bankAccounts, ...this.cashAccounts, ...this.creditAccounts];
  }

  get sum() {
    return this.financeAccounts.reduce((acc: number, account: FinanceAccount) => {
      return acc + account.balance;
    }, 0);
  }
}
