
































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_CREDIT_ACCOUNTS, GET_CREDIT_ACCOUNTS } from '../store/creditAccount';
import { CreditAccount, ListOfAssets } from '../types';
import { ApiResponse } from '@/components/types';
import CardRow from '@/components/CardRow.vue';
import CreditAccountCard from '@/modules/listOfAssets/components/CreditAccountCard.vue';

const CreditAccount = namespace('listOfAssets/creditAccount');

@Component({ components: { CreditAccountCard, CardRow } })
export default class CreditAccountsPlugin extends Vue {
  @Prop({ type: Object, required: true }) listOfAssets!: ListOfAssets;
  @Prop({ type: String, default: 'finance' }) referer!: string;
  @Prop({ type: Boolean, default: false }) showExport!: boolean;

  @CreditAccount.Getter(GET_CREDIT_ACCOUNTS) findByListOfAssets!: (listOfAssetsId: string) => CreditAccount[];
  @CreditAccount.Action(FETCH_CREDIT_ACCOUNTS) fetch!: (listOfAssetsId: string) => Promise<ApiResponse<CreditAccount[]>>;

  loading: boolean = false;
  showClosed: boolean = false;

  get accounts(): CreditAccount[] {
    const weight: { [type: string]: number } = {
      deposit_account: 0,
      funerary_account: 1,
      stocks_equity_funds: 2,
      account_in_credit: 3,
      accounts_payable: 4
    };

    return this.findByListOfAssets(this.listOfAssets.id)
      .filter((account) => {
        if (this.showClosed) {
          return true;
        }

        return account.state === 'open';
      })
      .sort((first, second) => {
        return weight[first.type] - weight[second.type];
      });
  }

  created() {
    return this.fetch(this.listOfAssets.id);
  }
}
