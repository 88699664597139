








import { Component, Prop, Vue } from 'vue-property-decorator';
import BankAccountForm from './BankAccountForm.vue';
import { SAVINGS_ACCOUNT } from '@/modules/listOfAssets/statics';

@Component({
  components: {
    BankAccountForm
  }
})
export default class SavingsAccountForm extends Vue {
  @Prop({ type: String, required: true }) listOfAssetsId!: string;

  type = SAVINGS_ACCOUNT;
}
