



















































import { Component, Prop, Vue } from 'vue-property-decorator';
import CardRow from '@/components/CardRow.vue';
import { CreditAccount, ListsOfAssets } from '../types';
import TransactionExportDialog from './TransactionExportDialog.vue';
import FinanceAccountRemoveDialog from './FinanceAccountRemoveDialog.vue';
import VoucherExportDialog from '@/modules/listOfAssets/components/VoucherExportDialog.vue';

@Component({
  components: {
    VoucherExportDialog,
    FinanceAccountRemoveDialog,
    TransactionExportDialog,
    CardRow
  }
})
export default class CreditAccountCard extends Vue {
  @Prop({ type: Object, required: true }) account!: CreditAccount;
  @Prop({ type: Object, required: true }) listOfAssets!: ListsOfAssets;
  @Prop({ type: Boolean, default: false }) showExport!: boolean;
  @Prop({ type: String, default: 'finance' }) referer!: string;
}
