






























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import DatePicker from '@/components/form/DatePicker.vue';
import BankSelect from '@/modules/bank/components/BankSelect.vue';
import IbanField from '../IBANField.vue';
import { BankAccountType, OpenBankAccount } from '@/modules/listOfAssets/types';
import { ApiResponse } from '@/components/types';
import { createOpenBankAccount } from '@/modules/listOfAssets/model';
import { OPEN_BANK_ACCOUNT } from '@/modules/listOfAssets/store/bankAccount';

const BankAccount = namespace('listOfAssets/bankAccount');

@Component({
  components: {
    IbanField,
    DatePicker,
    BankSelect
  }
})
export default class BankAccountForm extends Vue {
  @Prop({ type: String, required: true }) listOfAssetsId!: string;
  @Prop({ type: String, required: true }) type!: BankAccountType;

  @BankAccount.Action(OPEN_BANK_ACCOUNT) openBankAccount!: (params: OpenBankAccount) => Promise<ApiResponse>;

  error: any = null;

  value: OpenBankAccount = createOpenBankAccount(this.listOfAssetsId, this.type);

  get form() {
    return this.$refs.form;
  }

  reset() {
    this.error = null;
    this.value = createOpenBankAccount(this.listOfAssetsId, this.type);
  }

  cancel() {
    this.reset();
    this.$emit('cancel');
  }

  back() {
    this.reset();
    this.$emit('back');
  }

  save() {
    this.error = null;

    return this.openBankAccount(this.value);
  }
}
