








import { Component, Prop, Vue } from 'vue-property-decorator';
import CreditAccountForm from './CreditAccountForm.vue';
import { STOCKS_EQUITY_FUNDS } from '../../statics';

@Component({
  components: {
    CreditAccountForm
  }
})
export default class StocksEquityFundsForm extends Vue {
  @Prop({ type: String, required: true }) listOfAssetsId!: string;

  type = STOCKS_EQUITY_FUNDS;
}
