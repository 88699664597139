








import { Component, Prop, Vue } from 'vue-property-decorator';
import BankAccountForm from './BankAccountForm.vue';
import { BUILDING_LOAN_CONTRACT } from '@/modules/listOfAssets/statics';


@Component({
  components: {
    BankAccountForm
  }
})
export default class BuildLoanContractForm extends Vue {
  @Prop({ type: String, required: true }) listOfAssetsId!: string;

  type = BUILDING_LOAN_CONTRACT;
}
