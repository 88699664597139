

























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import DatePicker from '@/components/form/DatePicker.vue';
import IncomeProducingSelect from '../IncomeProducingSelect.vue';
import { CreditAccountType, OpenCreditAccount } from '@/modules/listOfAssets/types';
import { ApiResponse } from '@/components/types';
import { createOpenCreditAccount } from '@/modules/listOfAssets/model';
import { OPEN_CREDIT_ACCOUNT } from '@/modules/listOfAssets/store/creditAccount';

const CreditAccount = namespace('listOfAssets/creditAccount');

@Component({
  components: {
    IncomeProducingSelect,
    DatePicker
  }
})
export default class CreditAccountForm extends Vue {
  @Prop({ type: String, required: true }) listOfAssetsId!: string;
  @Prop({ type: String, required: true }) type!: CreditAccountType;

  @CreditAccount.Action(OPEN_CREDIT_ACCOUNT) openCreditAccount!: (openAccount: OpenCreditAccount) => Promise<ApiResponse>;

  error: any = null;

  value = createOpenCreditAccount(this.listOfAssetsId, this.type);

  reset() {
    setTimeout(() => {
      this.error = null;
      this.value = createOpenCreditAccount(this.listOfAssetsId, this.type);
    }, 300);
  }

  cancel() {
    this.reset();
    this.$emit('cancel');
  }

  back() {
    this.reset();
    this.$emit('back');
  }

  save() {
    this.error = null;

    return this.openCreditAccount(this.value);
  }
}
