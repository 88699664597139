


























































import { Component, Prop, Vue } from 'vue-property-decorator';
import CardRow from '@/components/CardRow.vue';
import { BankAccount, ListsOfAssets } from '../types';
import TransactionExportDialog from './TransactionExportDialog.vue';
import VoucherExportDialog from './VoucherExportDialog.vue';
import FinanceAccountRemoveDialog from './FinanceAccountRemoveDialog.vue';

const CreateLetterButton = () => import('../../correspondence/components/CreateLetterButton.vue');

@Component({
  components: {
    FinanceAccountRemoveDialog,
    TransactionExportDialog,
    VoucherExportDialog,
    CreateLetterButton,
    CardRow
  }
})
export default class BankAccountCard extends Vue {
  @Prop({ type: Object, required: true }) account!: BankAccount;
  @Prop({ type: Object, required: true }) listOfAssets!: ListsOfAssets;
  @Prop({ type: Boolean, default: false }) showExport!: boolean;
  @Prop({ type: String, default: 'finance' }) referer!: string;
}
