
































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_CASH_ACCOUNTS, GET_CASH_ACCOUNTS } from '../store/cashAccount';
import { CashAccount, ListOfAssets } from '../types';
import { ApiResponse } from '@/components/types';
import CardRow from '@/components/CardRow.vue';
import CashAccountCard from './CashAccountCard.vue';

const CashAccount = namespace('listOfAssets/cashAccount');

@Component({ components: { CashAccountCard, CardRow } })
export default class CashAccountsPlugin extends Vue {
  @Prop({ type: Object, required: true }) listOfAssets!: ListOfAssets;
  @Prop({ type: String, default: 'finance' }) referer!: string;
  @Prop({ type: Boolean, default: false }) showExport!: boolean;

  @CashAccount.Getter(GET_CASH_ACCOUNTS) findByListOfAssets!: (listOfAssetsId: string) => CashAccount[];
  @CashAccount.Action(FETCH_CASH_ACCOUNTS) fetch!: (listOfAssetsId: string) => Promise<ApiResponse<CashAccount[]>>;

  loading: boolean = false;
  showClosed: boolean = false;

  get accounts(): CashAccount[] {
    return this.findByListOfAssets(this.listOfAssets.id).filter((account) => {
      if (this.showClosed) {
        return true;
      }

      return account.state === 'open';
    });
  }

  created() {
    return this.fetch(this.listOfAssets.id);
  }
}
